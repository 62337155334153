export const mixinPromise = (promiseInsidePromise = null, {begin = null, after = null})=>{
  typeof begin === 'function' && begin();
  return new Promise((resolve, reject) => {
    promiseInsidePromise
    .then(response=>{
      resolve(response);
      typeof after === 'function' &&  after(response, 'success');
    })
    .catch(response=>{
      reject(response);
      typeof after === 'function' &&  after(response, 'fail');
    });
  })
}
