import { DELETE_MODEL, SWITCH_COLUMN } from "@/store/modules/common/common.module";
import { mixinPromise } from "@c@/helpers/mixin.promise";

export default {
  methods: {
    switchColumn({resource, id, column = 'is_show', mutationType, begin = null, after = null}){
      return mixinPromise(
      this.$store.dispatch(SWITCH_COLUMN, { resource, id, column, mutationType }),
        { begin, after }
      );
    },
    deleteModel({resource, id, mutationType, begin = null, after = null}){
      return mixinPromise(
        this.$store.dispatch(DELETE_MODEL, { resource, id, mutationType }),
        { begin, after }
      );
    }
  }
}
