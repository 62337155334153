<template>
  <div class="user-list">
    <error :errors="userError" />
    <portlet fluidHeight>
      <template v-slot:body>
        <div class="kt-widget kt-widget--user-profile-3">
          <div class="kt-widget__top">
            <div class="kt-widget__media">
              <b-avatar :src="user.avatar" text="!" size="100px" rounded="" />
            </div>
            <div class="kt-widget__content">
              <div class="kt-widget__head">
                <div class="kt-widget__user">
                  <a href="javascript:;" class="kt-widget__username">
                    {{ user.nickname }}
                  </a>
                  <span class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success cursor-default mx-1">ۋاكالەتچى</span>
                </div>
              </div>
              <div class="kt-widget__subhead">
                <a href="javascript:;" class="cursor-default" v-if="user.phone" v-b-tooltip title="تېلفۇن نۇمۇرى"><i class="fa fa-mobile"></i> {{ user.phone }} </a>
              </div>
              <div class="kt-widget__subhead" v-if="user.wallet">
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="ئۇمۇمى كىرىمى"><i class="fa fa-wallet"></i> {{ user.wallet.total || 0.00 }} ￥ </a>
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="قالدۇق سوممىسى"><i class="fa fa-credit-card"></i> {{ user.wallet.remainder || 0.00 }} ￥ </a>
              </div>
              <!--<div class="kt-widget__info">
                <div class="kt-widget__desc"></div>
              </div>-->
            </div>
          </div>
          <div class="kt-widget__bottom">
            <div class="kt-widget__item">
              <div class="kt-widget__icon">
                <i class="flaticon2-group"></i>
              </div>
              <div class="kt-widget__details cursor-default">
                <span class="kt-widget__title">ئەكىرگەن ئەزاسى</span>
                <span class="kt-widget__value">{{ user.invitees_count }}<span>نەپەر</span></span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="user.profit_ratio">
              <div class="kt-widget__icon">
                <i class="flaticon-pie-chart"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`ئونىۋېرسال پايدا نىسپىتى`">
                <span class="kt-widget__title">پايدا نىسبىتى</span>
                <span class="kt-widget__value">{{ user.profit_ratio.ratio }}<span>%</span></span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="membershipReward.length" >
              <div class="kt-widget__icon" >
                <i class="flaticon-notepad"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`ئادەتتىكى VIP ئەزالىق پايدا سوممىسى، خالىغان بىر سوممىنى تاللاپ پايدا ئايرىلىدۇ`">
                <span class="kt-widget__title">VIP ئەزالىق پايدا سوممىسى</span>
                <span class="kt-widget__value">{{ membershipReward.map(item=> item.value).join('يۈەن، ') }} يۈەن</span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="entrustReward.length">
              <div class="kt-widget__icon">
                <i class="flaticon-rotate"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`كىلىشىم ئەزالىق پايدا سوممىسى، خالىغان بىر سوممىنى تاللاپ پايدا ئايرىلىدۇ`">
                <span class="kt-widget__title">كېلىشىم ئەزالىق پايدا سوممىسى</span>
                <span class="kt-widget__value">{{ entrustReward.map(item=> item.value).join('يۈەن، ') }} يۈەن</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }

  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
      padding-right: 1rem;
      padding-top: 0;
    }

    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
      padding: 0.5rem 0;
    }
  }

</style>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import Error from "../../../partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import sweetMixin from "@m@/common/sweetAlert.mixin";
  import userMixin from "@m@/user.mixin";
  import walletMixin from "@m@/wallet.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";

  export default {
    name: "index",
    components: { Error, Portlet, Datetime, paginate },
    mixins: [ sweetMixin, userMixin, formBusyMixin ],
    computed: {
      userId(){
        let {id} = this.$route.params;
        return id;
      },
      membershipReward(){
        if (this.user.hasOwnProperty('profit_ratio'))
          return this.user.profit_ratio.membership_reward || [];
        return [];
      },
      entrustReward(){
        if (this.user.hasOwnProperty('profit_ratio'))
          return this.user.profit_ratio.entrust_reward || [];
        return [];
      }
    },
    created() {
      this.getUser(this.userId);
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئەزا باشقۇرۇش', route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({ title: "ئەزا تەپسىلاتى" });
    },
    data(){
      return {
        formBusy: false
      };
    },
    methods: {}
  }
</script>
