<template>
  <div class="vip_expires-modal">
    <base-modal :id="id" :title="title" :modal-busy="userBusy" :size="size" v-model="modalState" >
      <b-row>
        <b-col>
          <b-form-group id="input-group-vip_expires_in" label="ئالىي ئەزالىق ئۈنۈملۈك ۋاقتى" label-for="vip_expires_in" description="يىل ئاي كۈن ۋاقتىنى توغرا تاللىغايسىز">
            <datetime placeholder="ۋاقىت تاللاڭ" id="vip_expires_in" v-model="formData.vip_expires_in" class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button :disabled="!formData.vip_expires_in" @click="onSubmit" id="submit" size="sm" variant="primary">
        ساقلاش
      </b-button>
    </base-modal>
  </div>
</template>

<script>
  import baseModal from "@v@/components/modals/base.modal";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import userMixin from "@m@/user.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "UserVipExpiresModal",
    props: {
      id: {
        type: String,
        default: () => 'user-vip-expires-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'ئالىي ئەزالىق ۋاقتىنى تەڭشەش'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      options: {
        type: Object
      },
      userId: {
        type: [Number, String]
      }
    },
    components: { baseModal, Datetime },
    mixins: [ formBusyMixin, userMixin ],
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      modalState: function(val) {
        if ( ! val ){
          this.formData.vip_expires_in = '';
        }
      }
    },
    data(){
      return {
        userBusy: false,
        formData: {
          vip_expires_in: '',
        }
      };
    },
    methods: {
      onSubmit(){
        this.updateUserVipExpiresIn(this.userId, this.formData)
          .then(()=>{
            this.$emit('updated');
          })
          .catch(()=>{
            this.$emit('failed');
            this.hideBusy('userBusy');
          })
      },
    }
  }
</script>

<style>
  .vdatetime-input {
    width: 100%;
    height: 100%;
    border: unset;
  }
</style>
