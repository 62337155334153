import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_LATEST_USER,
  GET_USER,
  GET_USER_WEEK_STATISTICS,
  GET_USERS,
  SEARCH_USER,
  SWITCH_USER_LOCKED,
  UPDATE_USER_REWARD,
  UPDATE_USER_VIP_EXPIRES_IN
} from "@/store/modules/user.module";

export default {
  computed: {
    ...mapGetters(['users', 'usersMeta', 'user', 'userError', 'searchUserList', 'searchUserListMeta', 'latestUsers', 'userWeekStatistics'])
  },
  methods: {
    getUserWeekStatistics(actions = {
      begin: ()=>{
        this.showBusy && this.showBusy('userBusy');
        debugConsole({message: 'loading'});
      },
      after: (response, state)=>{
        this.hideBusy && this.hideBusy('userBusy');
        debugConsole({message: 'loaded'});
      },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_USER_WEEK_STATISTICS),
        actions
      );
    },
    getLatestUsers(actions = {
      begin: ()=>{
        this.showBusy && this.showBusy('userBusy');
        debugConsole({message: 'loading'});
      },
      after: (response, state)=>{
        this.hideBusy && this.hideBusy('userBusy');
        debugConsole({message: 'loaded'});
      },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_LATEST_USER),
      actions
      );
    },
    getUser(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_USER, id),
      actions
      );
    },
    getUsers(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('userBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('userBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_USERS, {page, title}),
        actions
      );
    },
    searchUser(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(SEARCH_USER, {title, page}),
        actions
      );
    },
    switchUserLockState(user_id, reason = '', actions = {
      begin: ()=>{ this.showBusy('userBusy'); debugConsole({message: 'switching'}); },
      after: (response, state)=>{ this.hideBusy('userBusy'); debugConsole({message: 'switched'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(SWITCH_USER_LOCKED, {user_id, reason}),
      actions
      );
    },
    updateUserReward(user_id, payload = {}, actions = {
      begin: ()=>{ this.showBusy('userBusy'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.hideBusy('userBusy'); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_USER_REWARD, {user_id, payload}),
      actions
      );
    },
    updateUserVipExpiresIn(user_id, payload = {}, actions = {
      begin: ()=>{ this.showBusy('userBusy'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.hideBusy('userBusy'); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_USER_VIP_EXPIRES_IN, {user_id, payload}),
      actions
      );
    }
  }
}
