<template>
  <base-modal :id="id" :title="title" :modal-busy="busy" :size="size" v-model="modalState" >
    <b-row>
      <b-col>
        <b-form-group :label="label">
          <b-input-group>
            <b-input :placeholder="placeholder" v-model="formData.reason" :disabled="lockState" />
            <b-input-group-append>
              <b-button size="sm" @click="onClickSend" text="Button" variant="success">ساقلاش</b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="reason-live-feedback"></b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import userMixin from "@m@/user.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "userSwitchLockModal",
    components: { baseModal },
    mixins: [ userMixin, formBusyMixin ],
    props: {
      id: {
        type: String,
        default: () => 'time-fragment-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'قارا تىزىملىك ئۇچۇرى يېڭىلاش'
      },
      busy: Boolean,
      size: {
        type: String,
        default: ()=> 'lg'
      },
      lockState: Boolean,
      userId: {
        type: [Number, String]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      lockState: {
        handler(val, oldVal){
          if ( val ){
            this.placeholder = 'نۆۋەتتىكى ئەزانى قارا تىزىملىكتىن بىكار قىلماقچى بولىۋاتىسىز، سەۋەبىنى يازمىسىڭىزمۇ بولىدۇ';
            this.label = 'بىكار قىلىش سەۋەبى';
          }else if( ! val ){
            this.placeholder = 'قارا تىزىملىككە قوشۇش سەۋەبىنى يېزىڭ';
            this.label = 'قوشۇش سەۋەبى';
          }else {
            this.placeholder = 'قارا تىزىملىككە قوشۇش سەۋەبىنى يېزىڭ';
            this.label = 'قوشۇش سەۋەبى';
          }
        },
      }
    },
    mounted() {

    },
    data(){
      return {
        formData: {
          reason: null
        },
        placeholder: 'سەۋەبىنى يېزىڭ',
        label: 'قوشۇش سەۋەبى'
      };
    },
    methods: {
      onClickSend(){
        this.switchUserLockState(this.userId, this.formData.reason, {
          begin: ()=> { this.$emit('switching') }
        })
          .then(()=>{
            this.$emit('switched');
            this.$nextTick(()=>{
              this.formData.reason = null;
            });
          })
          .catch(()=>{
            this.$emit('failed')
          })
      },
    }
  }
</script>

<style scoped>

</style>
