import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import {
  CONFIRM_CASH_REQUEST,
  GET_USER_WALLET_LOGS, GET_USER_WALLET_STATISTICS,
  GET_WALLET_CASH_REQUEST_LIST, GET_WALLET_CASH_REQUEST_STATISTICS
} from "@/store/modules/wallet.module";

export default {
  computed: {
    ...mapGetters(["cashRequest", "cashRequestList", "cashRequestMeta", "walletError", "userWalletLogs", "userWalletLogsMeta", 'userWalletStatistics', 'enableList', 'cashRequestStatistics'])
  },
  methods: {
    getUserWalletLogs(id, page = 1, params = {}, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_USER_WALLET_LOGS, {id, page, params}),
      actions
      );
    },
    getUserWalletStatistics(id, params = {}, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_USER_WALLET_STATISTICS, {id, params}),
      actions
      );
    },
    getCashRequestList(filter = 0, page = 1, params= {}, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_WALLET_CASH_REQUEST_LIST, {filter, page, params}),
      actions
      );
    },
    handleConfirmCashRequestApi(options, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(CONFIRM_CASH_REQUEST, options),
      actions
      );
    },
    getCashStatistics(params = {}, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_WALLET_CASH_REQUEST_STATISTICS, {params}),
      actions
      );
    },
  }
}
